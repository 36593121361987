import React from "react";
import {
    EditGuesser,
    InputGuesser,
    ListGuesser,
    FieldGuesser,
} from "@api-platform/admin";
import MapField from "../../Field/Map/MapField";
import {
    Create,
    required,
    useNotify,
    ImageInput,
    ImageField,
    TextInput,
    DateTimeInput,
    regex,
    SelectInput,
    NumberInput,
    useRecordContext,
    SimpleForm,
    BooleanField,
    useCreate,
    useRedirect,
} from "react-admin";
import {
    Grid,
    CircularProgress,
    Button,
} from "@mui/material";
import {ColorInput} from "react-admin-color-picker";
import {useController, useFormContext, useWatch} from "react-hook-form";

import {Link} from "react-router-dom";
import {
    usePermissions,
    useSafeSetState
} from "react-admin";

const domainsFilters = [
    <TextInput label="Search" source="searchtext" alwaysOn/>,
];

const colorRegex = /^$|^#[0-9a-f]{6}/i;
const colorMsg = "Color not valid";

const LevelChoices = [
    {id: 0, name: "Local"},
    {id: 1, name: "Street"},
    {id: 2, name: "Town"},
    {id: 3, name: "City"},
];

const LinkToServices = () => {
    const record = useRecordContext();

    return record ? (
        <Button
            color="primary"
            component={Link}
            to={{
                pathname: "/services",
                search: `filter=${JSON.stringify({"domain.name": record["name"]})}`,
            }}
        >
            Services
        </Button>
    ) : null;
};

const UploadedImage = () => {
    const record = useRecordContext();
    const image = useController({name: "mediaObject"});
    const watchImage = useWatch({name: "mediaObject"});

    return !!record.image && watchImage !== null ? (
        <>
            <ImageField source="image" title="title"/>
            <Button onClick={() => image.field.onChange(null)}>Remove image</Button>
        </>
    ) : null;
};

const NFTButtons = () => {
    const {setValue, getValues} = useFormContext();
    const [loading, setLoading] = useSafeSetState(false);

    let minted = useWatch({name: 'minted'});
    const mintNFT = () => {
        setLoading(true);
        fetch(`${process.env.REACT_APP_BLOCKCHAIN_API_URL}/nfts`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${process.env.REACT_APP_BLOCKCHAIN_API_TOKEN}`
                },
                body: JSON.stringify({tokenId: getValues('originId')})
            })
            .then((response) => response.json())
            .then((res) => {
                if (res.success || res.data?.error?.reason.includes('ERC721: token already minted')) {
                    setValue("minted", true, {shouldDirty: true});
                } else {
                    setValue("minted", false, {shouldDirty: true});
                }
                setLoading(false);
            })
    }

    const burnNFT = () => {
        setLoading(true);
        fetch(`${process.env.REACT_APP_BLOCKCHAIN_API_URL}/nfts/${getValues('originId')}`,
            {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${process.env.REACT_APP_BLOCKCHAIN_API_TOKEN}`
                }
            })
            .then((response) => response.json())
            .then((res) => {
                if (res.success || res.data?.error?.reason.includes('ERC721: invalid token ID')) {
                    setValue("minted", false, {shouldDirty: true});
                }

                setLoading(false);
            })
    }

    if (minted) {
        return (
            <>
                <TextInput label="NFT ID" fullWidth={true} source={"originId"} disabled/>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Button variant="contained" fullWidth={true}
                                onClick={() => window.open(process.env.REACT_APP_NFT_URL +'/'+ getValues('originId'), '_blank', 'noreferrer')}>
                            Watch NFT
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button variant="contained" fullWidth={true} color={'error'} onClick={burnNFT}
                                disabled={loading}>
                            Delete NFT
                            {loading && (
                                <CircularProgress
                                    color={'error'}
                                    size={19}
                                    thickness={3}
                                />
                            )}
                        </Button>
                    </Grid>
                </Grid>
            </>
        );

    } else {
        // setValue("minted", false);

        return (
            <>
                <Button variant="contained" fullWidth={true} onClick={mintNFT} disabled={loading}>
                    Create NFT
                    {loading && (
                        <CircularProgress
                            size={19}
                            thickness={3}
                        />
                    )}
                </Button>
            </>
        );
    }
};
const Rent = () => {
    const {setValue, getValues} = useFormContext();
    const [loading, setLoading] = useSafeSetState(false);
    // const [loading, setLoading] = useSafeSetState(false);

    let minted = useWatch({name: 'minted'});
    let rented = useWatch({name: 'rented'});
    // let minted = useWatch({name: 'expirationDt'});
    // let rentWalletAddress = useWatch({name: 'rentWalletAddress'});
    const getRent = () => {
        // setLoading(true);
        fetch(`${process.env.REACT_APP_BLOCKCHAIN_API_URL}/nfts/${getValues('originId')}/rent`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${process.env.REACT_APP_BLOCKCHAIN_API_TOKEN}`
                }
            })
            .then((response) => response.json())
            .then((res) => {
                if (res.success && res.data.tenantAddress) {
                    setValue("rented", true);
                    setValue("tenantAddress", res.data.tenantAddress);
                    setValue("startDt", res.data.startDt);
                    setValue("expirationDt", res.data.expirationDt);
                }

            })
    }
    getRent();

    const rentNFT = () => {
        setLoading(true);
        fetch(`${process.env.REACT_APP_BLOCKCHAIN_API_URL}/nfts/${getValues('originId')}/rent`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${process.env.REACT_APP_BLOCKCHAIN_API_TOKEN}`
                },
                body: JSON.stringify({
                    walletAddress: getValues('tenantAddress'),
                    startDt: getValues('startDt'),
                    expirationDt: getValues('expirationDt'),
                })
            })
            .then((response) => response.json())
            .then((res) => {
                if (res.success || res.data?.error?.reason.includes('ERC721: token already minted')) {
                    setValue("rented", true, {shouldDirty: true});
                } else {
                    setValue("rented", false, {shouldDirty: true});
                }
                setLoading(false);
            })
    }

    const delRentNFT = () => {
        setLoading(true);
        fetch(`${process.env.REACT_APP_BLOCKCHAIN_API_URL}/nfts/${getValues('originId')}/rent`,
            {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${process.env.REACT_APP_BLOCKCHAIN_API_TOKEN}`
                }
            })
            .then((response) => response.json())
            .then((res) => {
                if (res.success || res.data?.error?.reason.includes('ERC721: invalid token ID')) {
                    setValue("rented", false, {shouldDirty: true});
                }

                setLoading(false);
            })
    }

    if (minted) {

        return (
            <>
                <TextInput fullWidth={true} source={"tenantAddress"} disabled={rented}/>
                <DateTimeInput fullWidth={true} source={"startDt"} disabled={rented}/><br/>
                <DateTimeInput fullWidth={true} source={"expirationDt"} disabled={rented}/><br/>
                {
                    rented ? (
                            <Button variant="contained" fullWidth={true} color={'error'} onClick={delRentNFT}
                                    disabled={loading}>
                                Delete rent
                                {loading && (
                                    <CircularProgress
                                        color={'error'}
                                        size={19}
                                        thickness={3}
                                    />
                                )}
                            </Button>
                        ) :
                        (
                            <Button variant="contained" fullWidth={true} onClick={rentNFT} disabled={loading}>
                                Rent NFT
                                {loading && (
                                    <CircularProgress
                                        size={19}
                                        thickness={3}
                                    />
                                )}
                            </Button>

                        )
                }
            </>
        );
    } else {

    }


};


export const DomainCreate = (props) => {
    const notify = useNotify();
    const redirect = useRedirect();
    const {permissions} = usePermissions();
    const [create] = useCreate();
    const save = React.useCallback(
        async (values) => {
            try {
                if (!values.geometry) {
                    notify("Please create a polygon in order to create a new domain", {
                        type: "error",
                    });
                    return false;
                }
                if(!values.minted) values.minted = false;
                await create("domains", {data: values}, {returnPromise: true});
                redirect("/domains");
            } catch (error) {
                if (error.body.errors) {
                    return error.body.errors;
                }
            }
        },
        [create]
    );

    return (
        <Create {...props}>
            <SimpleForm onSubmit={save}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <MapField
                            roles={permissions}
                            source={"geometry"}
                            style={{height: "500px", margin: "2rem", ...props.style}}
                        />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <InputGuesser source={"name"} fullWidth={true}/>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <InputGuesser source={"url"} fullWidth={true}/>
                    </Grid>
                    <Grid item xs={12} lg={9}>
                        <TextInput source={"address"} fullWidth={true}/>
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <TextInput source={"zip"} fullWidth={true}/>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <InputGuesser source={"city"} fullWidth={true}/>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <InputGuesser source={"country"} fullWidth={true}/>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <TextInput source={"assistantUrl"} fullWidth={true}/>
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <SelectInput source={"situm"} choices={[
                            {id: true, name: "Habilitado"},
                            {id: false, name: "Deshabilitado"},
                            ]} defaultValue={false} fullWidth={true}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <TextInput source={"altitude"} fullWidth={true}/>
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <ColorInput
                            source="fgcolor"
                            validate={regex(colorRegex, colorMsg)}
                            label={"Text color"}
                            fullWidth={true}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <ColorInput
                            source="bgcolor"
                            validate={regex(colorRegex, colorMsg)}
                            label={"Background color"}
                            fullWidth={true}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <SelectInput
                            source={"level"}
                            choices={LevelChoices}
                            validate={required()}
                            defaultValue={0}
                            fullWidth={true}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <NumberInput source={"minAge"} defaultValue={0} fullWidth={true}/>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <ImageField source="image" title="title"/>
                        <ImageInput source="mediaObject" accept="image/*">
                            <ImageField source="image" title="title"/>
                        </ImageInput>
                    </Grid>
                </Grid>
            </SimpleForm>
        </Create>
    );
};


export const DomainEdit = (props) => {
    const {permissions} = usePermissions();

    return (
        <EditGuesser {...props}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <MapField
                            roles={permissions}
                            source={"geometry"}
                            style={{height: "500px", margin: "2rem", ...props.style}}
                        />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <InputGuesser source={"name"} fullWidth={true}/>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <InputGuesser source={"url"} fullWidth={true}/>
                    </Grid>
                    <Grid item xs={12} lg={9}>
                        <TextInput source={"address"} fullWidth={true}/>
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <TextInput source={"zip"} fullWidth={true}/>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <InputGuesser source={"city"} fullWidth={true}/>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <InputGuesser source={"country"} fullWidth={true}/>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <TextInput source={"assistantUrl"} fullWidth={true}/>
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <SelectInput source={"situm"} choices={[
                            {id: true, name: "Habilitado"},
                            {id: false, name: "Deshabilitado"},
                            ]} defaultValue={false} fullWidth={true}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <TextInput source={"altitude"} fullWidth={true}/>
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <ColorInput
                            source="fgcolor"
                            validate={regex(colorRegex, colorMsg)}
                            label={"Text color"}
                            fullWidth={true}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <ColorInput
                            source="bgcolor"
                            validate={regex(colorRegex, colorMsg)}
                            label={"Background color"}
                            fullWidth={true}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <SelectInput
                            source={"level"}
                            choices={LevelChoices}
                            validate={required()}
                            defaultValue={0}
                            fullWidth={true}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <NumberInput source={"minAge"} defaultValue={0} fullWidth={true}/>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <UploadedImage/>
                        <ImageInput source="mediaObject" accept="image/*">
                            <ImageField source="image" title="title"/>
                        </ImageInput>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <LinkToServices {...props} />
                    </Grid>
                </Grid>
        </EditGuesser>
    );
};

export const DomainList = (props) => (
    <ListGuesser {...props} filters={domainsFilters}>
        <ImageField source="image" title="title"/>
        <FieldGuesser source={"name"}/>
        <FieldGuesser source={"url"} fullWidth={true}/>
        <FieldGuesser source={"address"}/>
        <FieldGuesser source={"zip"}/>
        <FieldGuesser source={"city"}/>
        <FieldGuesser source={"assistantUrl"}/>
        <FieldGuesser source={"situm"}/>
        <FieldGuesser source={"altitude"} />
    </ListGuesser>
);
