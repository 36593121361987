import React, { useCallback, useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import StaticMode from "@mapbox/mapbox-gl-draw-static-mode";
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

import { useController } from "react-hook-form";
import { useRecordContext } from "react-admin";

import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import "mapbox-gl/dist/mapbox-gl.css";

import CustomDataProvider from "../../AuthJs/CustomDataProvider";

const MapField = ({ roles, ...props }) => {
  const record = useRecordContext();
  const { field: geometry } = useController({ name: "geometry" });
  const [mounted, setMounted] = useState(false);
  const [map, setMap] = useState(null);
  const [canEdit, setCanEdit] = useState(false);
  const [nearbyDomains, setNearbyDomains] = useState([]);

  useEffect(() => {
    if (mounted) {
      return;
    }
  
    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_API_TOKEN;
  
    var map = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/mapbox/streets-v11",
      center: [2.174974, 41.4035864], //sagrada familia
      zoom: 16,
    });
  
    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
    });
  
    map.addControl(geocoder);
  
    if (geometry.value && !!record) {
      let centroidPoint = JSON.parse(record.centroid);
      map.setCenter([
        centroidPoint.coordinates[0],
        centroidPoint.coordinates[1],
      ]);
  
      const geometryCoord = JSON.parse(geometry.value);
  
      let bounds = null;
      if (geometryCoord.type === 'Polygon') {
        bounds = new mapboxgl.LngLatBounds(
          geometryCoord.coordinates[0][0],
          geometryCoord.coordinates[0][0]
        );
        for (const coord of geometryCoord.coordinates[0]) {
          bounds.extend(coord);
        }
      } else {
        bounds = new mapboxgl.LngLatBounds(
          geometryCoord.coordinates[0][0][0],
          geometryCoord.coordinates[0][0][0]
        );
        for (const coord of geometryCoord.coordinates[0][0]) {
          bounds.extend(coord);
        }
      }
  
      map.fitBounds(bounds, {
        padding: 20,
      });
    } else if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        map.setCenter([position.coords.longitude, position.coords.latitude]);
      });
    }
  
    map.on("moveend", () => {
      fetchNearbyDomains(map);
    });
    map.on("load", () => {
      fetchNearbyDomains(map);
    });
  
    setMap(map);
  
    setMounted(true);
  }, [mounted, record]);

  useEffect(() => {
    if (!!roles && !!map) {
      if (roles.includes("ROLE_ADMIN") || roles.includes("ROLE_DOMAIN_ADMIN")) {
        console.log("Your role is allowed to edit the map");
        setCanEdit(true);
      } else {
        console.log("Your role is not allowed to edit the map");
        var modes = MapboxDraw.modes;
        modes.static = StaticMode;
        const staticDraw = new MapboxDraw({
          modes: modes,
          displayControlsDefault: false,
        });

        map.addControl(staticDraw, "top-left");

        map.on("load", function () {
          staticDraw.changeMode("static");
        });

        if (!!geometry.value) {
          staticDraw.add(JSON.parse(geometry.value));
        }
      }
    }
  }, [roles, map]);

  useEffect(() => {
    if (canEdit && !!map) {
      const editDraw = new MapboxDraw({
        controls: {
          polygon: true,
          trash: true,
        },
        displayControlsDefault: false,
      });

      map.on("draw.create", (data) => updateGeometry(data));
      map.on("draw.update", (data) => updateGeometry(data));
      map.addControl(editDraw, "top-left");

      if (!!geometry.value) {
        editDraw.add(JSON.parse(geometry.value));
      }
    }
  }, [canEdit]);

  useEffect(() => {
    if (!!map && nearbyDomains.length > 0) {
      nearbyDomains.forEach((domain) => createMapSource(domain));
    }
  }, [nearbyDomains, map]);

  const fetchNearbyDomains = (map) => {
    const mapCenter = map.getCenter();
    CustomDataProvider.getNearbyDomains(
      mapCenter.lng + "," + mapCenter.lat
    ).then((resp) => {
      if (!!record) {
        setNearbyDomains(
          resp["hydra:member"].filter(
            (domain) => domain !== null && domain.uuid !== record.uuid
          )
        );
      } else {
        setNearbyDomains(
          resp["hydra:member"].filter((domain) => domain !== null)
        );
      }
    });
  };

  const updateGeometry = useCallback((data) => {
    geometry.onChange(JSON.stringify(data.features[0].geometry)); // data send back to hook form
  });

  const createMapSource = useCallback((domain) => {
    if (!map.getSource(domain.uuid)) {
      map.addSource(domain.uuid, {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: JSON.parse(domain.geometry),
        },
      });
    }

    if (!map.getLayer(domain.uuid)) {
      map.addLayer({
        id: domain.uuid,
        type: "line",
        source: domain.uuid,
        layout: {},
        paint: {
          "line-color": "#000",
          "line-width": 3,
        },
      });
    }

    let centroidPoint = JSON.parse(domain.centroid);

    map.fire("closeAllPopups");
    const popup = new mapboxgl.Popup({ closeOnClick: false })
      .setLngLat(centroidPoint.coordinates)
      .setHTML("<p><strong>" + domain.name + "</strong></p>")
      .addTo(map);
  });

  return <div id="map" style={props.style}></div>;
};
export default MapField;
