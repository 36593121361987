import React from "react";
import {
  CreateGuesser,
  EditGuesser,
  InputGuesser,
  ListGuesser,
  FieldGuesser,
} from "@api-platform/admin";
import { 
  ReferenceInput, 
  AutocompleteInput, 
  ImageField, 
  ImageInput,
  NumberInput,
  SelectInput,
  DateInput,
  useRecordContext 
} from "react-admin";
import { Button, Grid } from "@mui/material";
import {useController, useWatch} from "react-hook-form";

const UploadedImage = () => {
  const record = useRecordContext();
  const image = useController({name: "mediaObject"});
  const watchImage = useWatch({name: "mediaObject"});

  return !!record.image && watchImage !== null ? (
      <>
          <ImageField source="image" title="title"/>
          <Button onClick={() => image.field.onChange(null)}>Remove image</Button>
      </>
  ) : null;
};



export const PublicationCreate = (props) => (
  <CreateGuesser {...props}>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <InputGuesser source={"title"} label="Título" sx={{ width: '100%' }}/>
      </Grid>
      <Grid item xs={12}>
        <InputGuesser source={"description"} label="Descripción" InputProps={{ multiline: true, rows: 4 }} sx={{ width: '100%' }}/>
      </Grid>
      <Grid item xs={12} sm={4}>
        <ReferenceInput source="avatar" reference="avatars">
          <AutocompleteInput label="Avatar" optionText={"nick"} sx={{ width: '100%' }}/>
        </ReferenceInput>
      </Grid>
      <Grid item xs={12} sm={4}>
        <ReferenceInput source="domain" reference="domains" label="Domain">
          <AutocompleteInput
            optionText="name"
            filterToQuery={(searchText) => ({ searchtext: searchText })}
          />
        </ReferenceInput>
      </Grid>
      <Grid item xs={12} sm={4}>
        <DateInput source="pubDate" label="Fecha de Publicación" defaultValue={new Date().toISOString()} sx={{ width: '100%' }}/>
      </Grid>
      <Grid item xs={12}>
        <ImageField source="image" title="title" />
        <ImageInput source="mediaObject" accept="image/*" label="Imagen">
          <ImageField source="image" title="title" label="Imagen" />
        </ImageInput>
      </Grid>
      
    </Grid>
  </CreateGuesser>
);

export const PublicationEdit = (props) => (
  <EditGuesser {...props}>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <InputGuesser source={"title"} label="Título" sx={{ width: '100%' }}/>
      </Grid>
      <Grid item xs={12}>
        <InputGuesser source={"description"} label="Descripción" InputProps={{ multiline: true, rows: 4 }} sx={{ width: '100%' }}/>
      </Grid>
      <Grid item xs={12} sm={4}>
        <ReferenceInput source="avatar" reference="avatars">
          <AutocompleteInput label="Avatar" optionText={"nick"} sx={{ width: '100%' }}/>
        </ReferenceInput>
      </Grid>
      <Grid item xs={12} sm={4}>
        <ReferenceInput source="domain" reference="domains" label="Domain">
          <AutocompleteInput
            optionText="name"
            filterToQuery={(searchText) => ({ searchtext: searchText })}
          />
        </ReferenceInput>
      </Grid>
      <Grid item xs={12} sm={4}>
        <DateInput source="pubDate" label="Fecha Publicación" defaultValue={new Date().toISOString()} sx={{ width: '100%' }}/>
      </Grid>
      <Grid item xs={12} sm={6}>
        <UploadedImage />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ImageInput source="mediaObject" accept="image/*" label="Imagen">
          <ImageField source="image" title="title" label="Imagen" sx={{ width: '100%' }}/>
        </ImageInput>
      </Grid>
      <Grid item xs={12} sm={4}>
        <SelectInput source={"status"} label="Estado" choices={[
          { id: true, name: 'Habilitado' },
          { id: false, name: 'Deshabilitado' }
        ]} sx={{ width: '100%' }}/>
      </Grid>
      <Grid item xs={12} sm={4}>
        <NumberInput source={"reports"} label="Reportes" sx={{ width: '100%' }}/>
      </Grid>
      <Grid item xs={12} sm={4}>
        <SelectInput source={"isBlocked"} label="¿Bloqueado?" choices={[
          { id: false, name: 'NO' },
          { id: true, name: 'SI' }
        ]} sx={{ width: '100%' }}/>
      </Grid>
    </Grid>
  </EditGuesser>
);

export const PublicationList = (props) => (
  <ListGuesser {...props}>
    <FieldGuesser source={"title"} label="Título"/>
    <FieldGuesser source={"description"} label="Descripción" InputProps={{ multiline: true, rows: 4 }}/>
    <ImageField source="image" title="image" label="Imagen"/>
    <FieldGuesser source={"avatar"} label="Avatar"/>
    <FieldGuesser source={"domain"} label="Dominio"/>
    <FieldGuesser source={"pubDate"} label="Fecha Publicación"/>
    <FieldGuesser source={"status"} label="Estado"/>
    <FieldGuesser source={"reports"} label="Reportes" />
    <FieldGuesser source={"isBlocked"} label="¿Bloqueado?"/>
  </ListGuesser>
);
