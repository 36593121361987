import React from "react";
import {
  CreateGuesser,
  EditGuesser,
  InputGuesser,
  ListGuesser,
  FieldGuesser,
} from "@api-platform/admin";
import { 
  ReferenceInput, 
  AutocompleteInput, 
  ImageField, 
  ImageInput,
  NumberInput,
  SelectInput,
  BooleanField,
  useRecordContext 
} from "react-admin";
import { Button, Grid } from "@mui/material";
import {useController, useWatch} from "react-hook-form";

const UploadedImage = () => {
  const record = useRecordContext();
  const image = useController({name: "mediaObject"});
  const watchImage = useWatch({name: "mediaObject"});

  return !!record.image && watchImage !== null ? (
      <>
          <ImageField source="image" title="title"/>
          <Button onClick={() => image.field.onChange(null)}>Remove image</Button>
      </>
  ) : null;
};



export const AnnouncementCreate = (props) => (
  <CreateGuesser {...props}>
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <InputGuesser source={"title"} label="Título" sx={{ width: '100%' }}/>
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectInput source={"type"} label="Type" choices={[
          { id: false, name: 'Demanda' },
          { id: true, name: 'Oferta' }
        ]} sx={{ width: '100%' }}/>
      </Grid>
      <Grid item xs={12}>
        <InputGuesser source={"description"} label="Descripción" InputProps={{ multiline: true, rows: 4 }} sx={{ width: '100%' }}/>
      </Grid>
      <Grid item xs={12} sm={4}>
        <InputGuesser source={"contact"} label="Contacto" sx={{ width: '100%' }}/>
      </Grid>
      <Grid item xs={12} sm={4}>
        <ReferenceInput source="avatar" reference="avatars" label="Avatar">
          <AutocompleteInput label="Avatar" optionText={"nick"} sx={{ width: '100%' }}/>
        </ReferenceInput>
      </Grid>
      <Grid item xs={12} sm={4}>
        <ReferenceInput source="domain" reference="domains" label="Domain">
          <AutocompleteInput
            optionText="name"
            filterToQuery={(searchText) => ({ searchtext: searchText })}
          />
        </ReferenceInput>
      </Grid>
      <Grid item xs={12}>
        <ImageInput source="mediaObject" accept="image/*" label="Imagen">
          <ImageField source="image" title="title" label="Imagen"/>
        </ImageInput>
      </Grid>
    </Grid>
  </CreateGuesser>
);

export const AnnouncementEdit = (props) => (
  <EditGuesser {...props}>
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <InputGuesser source={"title"} label="Título" sx={{ width: '100%' }}/>
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectInput source={"type"} label="Type" choices={[
          { id: false, name: 'Demanda' },
          { id: true, name: 'Oferta' }
        ]} sx={{ width: '100%' }}/>
      </Grid>
      <Grid item xs={12}>
        <InputGuesser source={"description"} label="Descripción" InputProps={{ multiline: true, rows: 4 }} sx={{ width: '100%' }}/>
      </Grid>
      <Grid item xs={12} sm={4}>
        <InputGuesser source={"contact"} label="Contacto" sx={{ width: '100%' }}/>
      </Grid>
      <Grid item xs={12} sm={4}>
        <ReferenceInput source="avatar" reference="avatars" label="Avatar">
          <AutocompleteInput label="Avatar" optionText={"nick"} sx={{ width: '100%' }}/>
        </ReferenceInput>
      </Grid>
      <Grid item xs={12} sm={4}>
        <ReferenceInput source="domain" reference="domains" label="Domain">
          <AutocompleteInput
            optionText="name"
            filterToQuery={(searchText) => ({ searchtext: searchText })}
          />
        </ReferenceInput>
      </Grid>
      <Grid item xs={12} sm={6}>
        <ImageInput source="mediaObject" accept="image/*" label="Imagen">
          <ImageField source="image" title="title" label="Imagen"/>
        </ImageInput>
      </Grid>
      <Grid item xs={12} sm={6}>
        <UploadedImage />
      </Grid>
      <Grid item xs={12} sm={4}>
        <SelectInput source={"status"} label="Status" choices={[
          { id: false, name: 'Habilitado' },
          { id: true, name: 'Deshabilitado' }
        ]} sx={{ width: '100%' }}/>
      </Grid>
      <Grid item xs={12} sm={4}>
        <NumberInput source={"reports"} label="Reports" defaultValue={0} sx={{ width: '100%' }}/>
      </Grid>
      <Grid item xs={12} sm={4}>
        <SelectInput source={"isBlocked"} label="Is Blocked?" choices={[
          { id: false, name: 'NO' },
          { id: true, name: 'SI' }
        ]} sx={{ width: '100%' }}/>
      </Grid>
    </Grid>
  </EditGuesser>
);

export const AnnouncementList = (props) => (
  <ListGuesser {...props}>
    <BooleanField source="type" label="Type" TrueLabel="Oferta" FalseLabel="Demanda" />
    <FieldGuesser source={"title"} />
    <FieldGuesser source={"description"} />
    <FieldGuesser source={"contact"} />
    <ImageField source="image" title="image" />
    <FieldGuesser source={"avatar"} />
    <FieldGuesser source={"domain"} />
    <FieldGuesser source={"status"} />
    <FieldGuesser source={"reports"} />
    <FieldGuesser source={"isBlocked"} />
  </ListGuesser>
);
